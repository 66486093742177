/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://lv4ltbvarfhjnky2ge2dpo24eq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:aaa0b60b-12a7-426f-947e-24e5f2a865f5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_233P8POFM",
    "aws_user_pools_web_client_id": "2t2nljq5trqqaubjf5i4nkjgrk",
    "oauth": {},
    "aws_user_files_s3_bucket": "vision-board-imagesdev-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
